<template>
  <b-modal
    id="create-resell-ticket-modal"
    size="xl"
    centered
    no-close-on-backdrop
    no-close-on-esc
    header-bg-variant="light-info"
    hide-footer
    :title="$t('resellTicket.form.title')"
    @show="onLoad"
  >
    <IAmOverlay :loading="loading">
      <validation-observer ref="refFormObserver">
        <b-form>
          <b-row>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                :name="$t('resellTicket.form.type')"
                vid="type"
                rules="required"
              >
                <b-form-group>
                  <template #label>
                    {{ $t('resellTicket.form.type') }}
                    <span class="text-danger">*</span>
                  </template>
                  <v-select
                    id="type"
                    v-model="createRTReq.type"
                    :placeholder="$t('resellTicket.form.ph.type')"
                    :options="resellTypes"
                    disabled
                    :clearable="false"
                    :class="getValidationState(validationContext) === false ? 'v-select-is-invalid is-invalid' : ''"
                    rules="required"
                  >
                    <template #option="data">
                      <span>{{ $t('resellTicket.type.' + data.label) }}</span>
                    </template>
                    <template #selected-option="data">
                      <span> {{ $t('resellTicket.type.' + data.label) }}</span>
                    </template>
                    <template #no-options>
                      {{ $t('noOptions') }}
                    </template>
                  </v-select>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('resellTicket.form.source')">
                <v-select
                  v-model="createRTReq.source"
                  :placeholder="$t('resellTicket.form.source')"
                  :options="sources"
                  :clearable="false"
                  disabled
                >
                  <template #option="data">
                    <span>
                      {{ data.label }}
                    </span>
                  </template>

                  <template #selected-option="data">
                    <span>
                      {{ data.label }}
                    </span>
                  </template>

                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group :label="$t('saleReport.columns.airline')">
                <v-select
                  v-model="createRTReq.airlineCode"
                  :placeholder="$t('saleReport.columns.airline')"
                  :options="airlineSupports"
                  :reduce="airline => airline.code"
                  label="code"
                  :clearable="false"
                  rules="required"
                  disabled
                >
                  <template #option="data">
                    <span>
                      {{ data.name }} ({{ data.code }})
                    </span>
                  </template>

                  <template #selected-option="data">
                    <span>
                      {{ data.name }} ({{ data.code }})
                    </span>
                  </template>

                  <template #no-options>
                    {{ $t('noOptions') }}
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                :name="$t('saleReport.columns.bookingCode')"
                vid="bookingCode"
                rules="required"
              >
                <b-form-group>
                  <template #label>
                    {{ $t('saleReport.columns.bookingCode') }}
                    <span
                      v-if="createRTReq.source === mamaFullName"
                      class="text-danger"
                    >*</span>
                  </template>

                  <v-select
                    id="bookingCode"
                    v-model="pnrs"
                    :placeholder="$t('saleReport.columns.bookingCode')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :class="getValidationState(validationContext) === false ? 'v-select-is-invalid is-invalid' : ''"
                    :clearable="false"
                    taggable
                    multiple
                    no-drop
                  >
                    <template #search="{ attributes, events }">
                      <input
                        v-model="inputPnr"
                        value=""
                        class="vs__search"
                        :required="!pnrs"
                        maxlength="6"
                        minlength="6"
                        v-bind="attributes"
                        v-on="events"
                        @input="(e) => handleDebounceBookingCode(e.target.value)"
                      >
                    </template>
                  </v-select>
                  <!-- <b-form-input
                    v-model="createRTReq.pnr"
                    :class="getValidationState(validationContext) === false ? 'v-select-is-invalid is-invalid' : ''"
                    :formatter="removeAccentsUpperCaseFormatter"
                    lazy-formatter
                    @blur="searchPNR"
                  /> -->
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                  <small class="text-danger">
                    {{ $t('resellTicket.note.pnrs') }}
                  </small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-for="(pax, paxIndex) in pnrPaxs"
              :key="pax.pnr"
              md="12"
              class="border-bottom mb-1"
            >
              <b-form-group>
                <template #label>
                  <h4>
                    {{ $t('saleReport.columns.bookingCode') }} {{ pax.pnr }}

                    <b-button
                      variant="flat-danger"
                      class="p-50 rounded-circle"
                      @click="reloadPaxList(pax.pnr)"
                    >
                      <IAmIcon
                        icon="revisionOutline"
                        size="18"
                      />
                    </b-button>
                  </h4>
                </template>

                <b-row
                  v-for="(item, paxLsIndex) in pax.paxList"
                  :key="paxLsIndex"
                >
                  <b-col md="2">
                    <v-select
                      v-model="item.paxType"
                      class="bg-white"
                      :placeholder="$t('saleReport.columns.paxType')"
                      :options="paxTypes"
                      :clearable="false"
                      disabled
                    >
                      <template #option="data">
                        <span>
                          {{ $t('reservation.' + data.label) }}
                        </span>
                      </template>

                      <template #selected-option="data">
                        <span>
                          {{ $t('reservation.' + data.label) }}
                        </span>
                      </template>

                      <template #no-options>
                        {{ $t('noOptions') }}
                      </template>
                    </v-select>
                  </b-col>
                  <b-col
                    md="9"
                    class=""
                  >
                    <b-row>
                      <b-col md="6">
                        <b-form-input
                          v-model="item.paxName"
                          class="bg-white w-30"
                          :placeholder="$t('customer.fullName')"
                          disabled
                          @input="$event => {
                            item.paxName = formatterInput($event);
                          }
                          "
                        />
                      </b-col>
                      <b-col md="3">
                        <validation-provider
                          #default="validationContext"
                          :name="$t('saleReport.columns.priceTicket')"
                          rules="required|min_value:100000"
                        >
                          <b-form-input
                            :value="item.priceTicket"
                            :state="getValidationState(validationContext) === false ? false : null"
                            :name="$t('saleReport.columns.priceTicket')"
                            class="d-none"
                          />
                          <IAmInputMoney
                            :id="`priceTicket-${paxIndex}-${paxLsIndex}`"
                            :value-money.sync="item.priceTicket"
                            :state="getValidationState(validationContext) === false ? false : null"
                            :placeholder="$t('saleReport.columns.priceTicket')"
                            :on-blur="validationContext.validate"
                          />
                          <b-tooltip
                            v-if="validationContext.errors[0]"
                            :target="`priceTicket-${paxIndex}-${paxLsIndex}`"
                            triggers="hover focus"
                            boundary="viewport"
                            variant="danger"
                          >
                            {{ validationContext.errors[0] }}
                          </b-tooltip>
                        </validation-provider>
                      </b-col>
                      <b-col md="3">
                        <validation-provider
                          #default="validationContext"
                          :name="$t('resellTicket.form.price')"
                          :rules="`required|only-positive|${(item.priceTicket - 50000) >= 0 ? `max_value:${item.priceTicket - 50000}` : ''}`"
                        >
                          <b-form-input
                            :value="item.price"
                            :state="getValidationState(validationContext) === false ? false : null"
                            :name="$t('resellTicket.form.price')"
                            class="d-none"
                          />
                          <IAmInputMoney
                            :id="`price-${paxIndex}-${paxLsIndex}`"
                            :value-money.sync="item.price"
                            :state="getValidationState(validationContext) === false ? false : null"
                            :placeholder="$t('resellTicket.form.price')"
                            :on-blur="validationContext.validate"
                          />
                          <b-tooltip
                            v-if="validationContext.errors[0]"
                            :target="`price-${paxIndex}-${paxLsIndex}`"
                            triggers="hover focus"
                            boundary="viewport"
                            variant="danger"
                          >
                            {{ validationContext.errors[0] }}
                          </b-tooltip>
                        </validation-provider>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col>
                    <b-button
                      id="apply-all-price"
                      variant="flat-info"
                      style="padding: 4px"
                      @click="handleApplyAll(item, pax.pnr)"
                    >
                      <feather-icon
                        class="cursor-pointer"
                        icon="CopyIcon"
                        size="20"
                      />
                    </b-button>

                    <b-tooltip
                      :target="`apply-all-price`"
                      triggers="hover focus"
                      boundary="viewport"
                      variant="info"
                    >
                      <div>{{ $t('fee.applyAll') }}</div>
                    </b-tooltip>
                    <b-button
                      id="deletepax"
                      variant="flat-danger"
                      class="p-50 rounded-circle"
                      @click="onDelete(paxLsIndex, pax.pnr)"
                    >
                      <IAmIcon
                        icon="delete"
                        size="18"
                      />
                    </b-button>
                    <b-tooltip
                      :target="`deletepax`"
                      triggers="hover focus"
                      boundary="viewport"
                      variant="danger"
                    >
                      <div>{{ $t('resellTicket.btn.delete') }}</div>
                    </b-tooltip>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>
            <b-col md="12">
              <small class="text-danger">(*) {{ $t('resellTicket.note.paxName') }}.</small> <br>
              <small class="text-danger">(*) {{ $t('resellTicket.note.price') }}</small>
            </b-col>
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                :name="$t('resellTicket.form.phone')"
                vid="phone"
                rules="required"
              >
                <b-form-group>
                  <template #label>
                    {{ $t('resellTicket.form.phone') }}
                    <span class="text-danger">*</span>
                  </template>
                  <b-form-input
                    v-model="createRTReq.phone"
                    v-remove-non-numeric-chars.allNumber
                    :name="$t('resellTicket.form.phone')"
                    :state="getValidationState(validationContext) === false ? false : null"
                    :placeholder="$t('resellTicket.form.ph.phone')"
                  />
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col md="6" />
            <b-col md="6">
              <validation-provider
                #default="validationContext"
                rules="required"
                :name="$t('resellTicket.form.expiredDate')"
                vid="resellTicket-expiredDate"
              >
                <b-form-group>
                  <template #label>
                    {{ $t('resellTicket.form.expiredDate') }}
                    <span class="text-danger">*</span>
                  </template>
                  <flat-pickr
                    v-model="createRTReq.expiredDate"
                    class="d-none"
                  />
                  <div
                    class="p-0 form-control"
                    :class="validationContext.errors[0] ? 'is-invalid' : ''"
                  >
                    <flat-pickr
                      id="resellTicket-expiredDate"
                      v-model="createRTReq.expiredDate"
                      :name="$t('resellTicket.form.expiredDate')"
                      :config="{
                        ...configFlatPickr,
                        altInputClass: 'form-control border-transparent px-50 py-0 height-fit',
                      }"
                      :placeholder="$t('topup.placeholderSelectDate')"
                    />
                  </div>
                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>

                  <small class="text-danger">Anh/Chị kiểm tra ngày hết hạn bảo lưu để chọn ngày hạn đăng bán phù
                    hợp</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col>
              <b-form-group
                :label="$t('resellTicket.form.status')"
                :placeholder="$t('resellTicket.form.status')"
              >
                <b-form-checkbox
                  v-model="createRTReq.isActive"
                  class="mt-1"
                  name="check-button"
                  switch
                >
                  {{ $t('resellTicket.btn.pushlishNow') }}
                </b-form-checkbox>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!-- Textarea -->
              <b-form-group
                :label="$t('resellTicket.form.note')"
                label-for="note"
                :placeholder="$t('resellTicket.form.ph.note')"
              >
                <b-form-textarea
                  id="note"
                  v-model="createRTReq.note"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$t('resellTicket.form.notePrivate')"
                label-for="note"
                :placeholder="$t('resellTicket.form.ph.notePrivate')"
              >
                <b-form-textarea
                  id="notePrivate"
                  v-model="createRTReq.notePrivate"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
        <div class="d-flex-center gap-3">
          <b-button
            variant="info"
            @click="handleSubmit"
          >
            {{ $t('save') }}
          </b-button>
          <b-button
            variant="secondary"
            @click="handleCancel"
          >
            {{ $t('cancel') }}
          </b-button>
        </div>
      </validation-observer>
    </IAmOverlay>
  </b-modal>
</template>

<script>
import {
  computed,
  ref,
  toRefs, watch, watchEffect,
} from '@vue/composition-api'
import {
  BButton,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormTextarea,
  BModal,
  BRow, BTooltip,
} from 'bootstrap-vue'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

import { removeAccents, removeAccentsUpperCaseFormatter } from '@/@core/comp-functions/forms/formatter-input'
import VueI18n from '@/libs/i18n'
import router from '@/router'
import { env } from '@/libs/env'

import formValidation from '@core/comp-functions/forms/form-validation'
import { formatCurrency } from '@core/utils/filter'

import useResellTicketHandle from '@saleReport/useResellTicketHandle'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BModal,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormInvalidFeedback,
    BButton,
    BTooltip,
    BFormCheckbox,
    flatPickr: () => import('vue-flatpickr-component'),
    vSelect: () => import('vue-select'),
    IAmOverlay: () => import('@/components/IAmOverlay.vue'),
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
  },

  props: {
    sellTicket: {
      type: Object,
      default: null,
      required: false,
    },
    isManager: {
      type: Boolean,
      required: false,
    },
  },

  setup(props, { emit, root }) {
    const {
      resellTicketStatus,
      createRTReq,
      createResellTicket,
      resellTypes,
      paxTypes,
      airlineSupports,
      sources,
      getPaxInfoByPnr,
      loading,
      resetForm,
    } = useResellTicketHandle()

    const { refFormObserver, getValidationState } = formValidation()

    const { sellTicket, isManager } = toRefs(props)
    const { pnrs, pnrPaxs } = toRefs(createRTReq.value)

    function handleCancel() {
      root.$bvModal.hide('create-resell-ticket-modal')
    }

    function handleSubmit(bvModalEvent) {
      bvModalEvent.preventDefault()
      this.$refs.refFormObserver
        .validate()
        .then(async success => {
          if (success) {
            this.$bvModal.show('modal-api-loading')
            const res = await createResellTicket()
            this.$bvModal.hide('modal-api-loading')
            if (res && !isManager.value) {
              const titleMsg = () => this.$createElement('div', { domProps: { innerHTML: this.$t('resellTicket.redirect') } })
              this.$bvModal
                .msgBoxConfirm(titleMsg(), {
                  title: this.$t('modal.redirect'),
                  size: 'sm',
                  okVariant: 'info',
                  okTitle: this.$t('modal.yes'),
                  cancelTitle: this.$t('modal.no'),
                  cancelVariant: 'outline-danger',
                  hideHeaderClose: true,
                  centered: true,
                })
                .then(value => {
                  handleCancel()
                  if (value) {
                    router.push({ name: 'apps-manager-resell-ticket' })
                  } else {
                    emit('refresh')
                  }
                })
            }
          }
        })
    }

    // format input
    function formatterInput(value) {
      const str = removeAccents(value)
      return str.toUpperCase().replace(/\s+/g, ' ')
    }

    function handleApplyAll(item, pnr) {
      const indexPnr = pnrPaxs.value.findIndex(e => e.pnr === pnr)
      pnrPaxs.value[indexPnr].paxList = pnrPaxs.value[indexPnr].paxList.map(e => ({ ...e, price: item.price, priceTicket: item.priceTicket }))
    }

    async function onDelete(_index, pnr) {
      const indexPnr = pnrPaxs.value.findIndex(e => e.pnr === pnr)
      pnrPaxs.value[indexPnr].paxList = pnrPaxs.value[indexPnr].paxList.filter((e, i) => i !== _index)
      if (pnrPaxs.value[indexPnr].paxList.length === 0) {
        pnrs.value = pnrs.value.filter(e => e !== pnr)
      }
    }
    const inputPnr = ref(null)

    function handleDebounceBookingCode(cc) {
      cc = cc.toUpperCase().replace(/\s+/g, '')
      inputPnr.value = cc
      if (cc.length === 6) {
        inputPnr.value = ''
        if (!pnrs.value.includes(cc)) pnrs.value.push(cc)
      }
    }

    const locale = computed(() => VueI18n.locale).value
    const configFlatPickr = ref(
      {
        dateFormat: 'Y-m-d',
        minDate: 'today',
        maxDate: '',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd-m-Y',
        shorthandCurrentMonth: true,
        disableMobile: true,
      },
    )

    async function reloadPaxList(pnr) {
      pnrPaxs.value.find(e => e.pnr === pnr).paxList = await getPaxInfoByPnr(pnr)
    }

    function onLoad() {
      inputPnr.value = null
      if (sellTicket.value && sellTicket.value.bookingCode) {
        pnrs.value = [sellTicket.value.bookingCode]
      } else {
        resetForm()
        pnrs.value = []
        loading.value = false
      }
    }

    watchEffect(() => {
      if (sellTicket.value) {
        Object.keys(createRTReq.value).forEach(key => {
          if (key in sellTicket.value) {
            createRTReq.value[key] = sellTicket.value[key]
          }
        })
        pnrs.value = [sellTicket.value.bookingCode]
      }
    })

    watch(pnrs, async val => {
      const oldPnrs = pnrPaxs.value.map(e => e.pnr)
      const newPnrs = val.filter(e => !oldPnrs.includes(e))
      const updatedPnrPaxs = pnrPaxs.value.filter(e => val.includes(e.pnr))
      const newPnrPaxs = await Promise.all(newPnrs.map(async pnr => {
        const paxList = await getPaxInfoByPnr(pnr)
        if (!paxList) pnrs.value = val.filter(e => e !== pnr)
        return { pnr, paxList }
      }))
      pnrPaxs.value = updatedPnrPaxs.concat(newPnrPaxs).filter(item => item.paxList !== null)
      createRTReq.value.pnrs = val
      createRTReq.value.pnrPaxs = pnrPaxs.value
    }, { deep: true })

    const mamaFullName = env.mamaFullName
    return {
      loading,
      configFlatPickr,
      formatCurrency,
      formatterInput,
      createRTReq,
      handleCancel,
      handleSubmit,
      resellTypes,
      airlineSupports,
      sources,
      paxTypes,
      resellTicketStatus,
      refFormObserver,
      getValidationState,
      onDelete,
      handleApplyAll,
      pnrs,
      pnrPaxs,
      reloadPaxList,
      removeAccentsUpperCaseFormatter,
      onLoad,
      handleDebounceBookingCode,
      inputPnr,
      mamaFullName,
    }
  },
}
</script>

<style lang="scss" scoped>
.form-control ::v-deep {
  .height-fit {
    height: 37px;
  }
}

.v-select-is-invalid ::v-deep {
  .vs__dropdown-toggle {
    border: 1px solid #EF5350 !important;
  }
}

#bookingCode {
  .vs__actions {
    display: none !important;
  }
}
</style>
